<template>
  <div class="app-container">
    <div class="filter-container">
<!--      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>-->
      <el-input v-model="listQuery.keyword" placeholder="请输入名称" style="width: 220px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row>
      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column label="内容" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.value }}
        </template>
      </el-table-column>
      <el-table-column v-if="user.user.type === 'admin'" label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">编辑</el-button>
<!--          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>

<!--    <div class="pagination-container">-->
<!--      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>-->
<!--    </div>-->

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="70px" style="width: 400px; margin-left:50px;">
        <el-form-item label="key" prop="name" v-if="!form.id">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="内容" prop="value">
          <el-input v-model="form.value" maxlength="20"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import request from '@/utils/request'
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: '',
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      form: {
        id: '',
        name: '',
        value: '',
        remark: '',
      },
      rules: {
        name: [{ required: true, message: 'key不能为空', trigger: 'blur' }],
        value: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }],
      },
      btnLoading: false,

    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(["schools", "school_id","user"])
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: '/api/backend/config/list',
        method: 'get',
        params: this.listQuery
      }).then(response => {
        this.list = response.data
        // this.total = response.data.total
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    resetForm() {
      this.form = {
        id: '',
        name: '',
        value: '',
        remark: '',
      }
      this.btnLoading = false
    },

    handleCreate() {
      this.resetForm()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.resetForm()
      this.form = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          request({
            url: '/api/backend/config/save',
            method: 'post',
            data: this.form
          }).then(response => {
            this.btnLoading = false
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration: 2000
            })
            this.getList();
          }).catch((err) => {
            this.btnLoading = false
          })
        }
      })
    },
    handleDel(val) {
      this.$confirm('确定要删除, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        request({
          url: '/api/backend/config/del',
          method: 'get',
          params: {id: val}
        }).then(()=>{
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
  }
}
</script>


<style>

</style>
